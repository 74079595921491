import { useEffect, useRef, useState } from 'react';

import { lineClamped } from '../utils/common';

export const ShowMore = (props: {
  lines: number;
  children: React.ReactNode;
  responsive?: Record<string, number>;
}): JSX.Element => {
  const [enableShowMore, setEnableShowMore] = useState<boolean>(false);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [activeClassName, setActiveClassName] = useState<string>('');
  const [inactiveClassName, setInactiveClassName] = useState<string>('');
  const [ready, setReady] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setReady(false);
    const active = [`line-clamp-${props.lines}`];
    const inactive = ['line-clamp-none'];
    if (props.responsive) {
      for (const [key, value] of Object.entries(props.responsive)) {
        active.push(`${key}:line-clamp-${value}`);
        inactive.push(`${key}:line-clamp-none`);
      }
    }
    setActiveClassName(active.join(' '));
    setInactiveClassName(inactive.join(' '));
    setReady(true);
  }, [props.lines, props.responsive]);

  useEffect(() => {
    if (!ref.current || !ready) {
      return;
    }
    if (lineClamped(ref.current)) {
      setEnableShowMore(true);
    }
  }, [ready]);

  const handleToggleShowMore = () => {
    setIsShowMore(!isShowMore);
  };

  if (!ready) return <></>;

  return (
    <>
      <div
        ref={ref}
        className={isShowMore ? inactiveClassName : activeClassName}
      >
        {props.children}
      </div>
      {enableShowMore && (
        <div className='text-sms text-secondary'>
          <button
            type='button'
            className='outline-none focus:outline-none'
            onClick={handleToggleShowMore}
          >
            (Show {isShowMore ? 'Less' : 'More'})
          </button>
        </div>
      )}
    </>
  );
};
