import 'video.js/dist/video-js.css';

import axios from 'axios';
import { useEffect, useMemo, useRef } from 'react';
import useSWRImmutable from 'swr/immutable';
import type videojs from 'video.js';

import { type Media, MediaFormatVersion, MediaType } from '@lp-lib/media';

import { useAwaitFullScreenConfirmCancelModal } from '../../components/ConfirmCancelModalContext';
import { ModalWrapper } from '../../components/ConfirmCancelModalContext/ModalWrapper';
import { GlobalLoading } from '../../components/GlobalLoading';
import { Loading } from '../../components/Loading';
import { PREDEFINED_MEDIA_LIST } from '../../components/MediaUploader/consts';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useChunkedDependency } from '../../hooks/useChunkedDependency';
import { useQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { assertExhaustive, err2s } from '../../utils/common';
import { MediaUtils } from '../../utils/media';

function DownloadModal(props: {
  url: string;
  filename: string;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  const { url, filename } = props;

  const { call: handleDownload, state } = useLiveAsyncCall(async () => {
    const response = await axios.get(url, {
      responseType: 'blob',
    });

    const u = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = u;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(u);

    props.onConfirm();
  });

  return (
    <ModalWrapper
      onClose={props.onCancel}
      borderStyle='gray'
      containerClassName='w-85'
    >
      <div className='text-white text-center p-8'>
        <div className='w-full text-2xl font-medium'>
          Do you want to download this media?
        </div>
        <div className='mt-8 w-full flex justify-center items-center gap-2'>
          <button
            type='button'
            onClick={props.onCancel}
            className='btn-secondary w-33 h-10 flex items-center justify-center'
          >
            Cancel
          </button>
          <button
            type='button'
            onClick={handleDownload}
            className='btn-primary w-33 h-10 flex items-center justify-center'
            disabled={state.state.isRunning}
          >
            {state.state.isRunning ? 'Downloading...' : 'Download'}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

export function VideoViewer(props: {
  media: Media | { videoUrl: string; posterUrl?: string };
  version?: string | null;
  autoplay?: boolean;
}): JSX.Element {
  const download = useQueryParam('download');
  const autoplay = download ? false : props.autoplay;

  const [url, posterUrl] = useMemo(() => {
    if (MediaUtils.IsMediaObject(props.media)) {
      const priority = [MediaFormatVersion.HD, MediaFormatVersion.Raw];
      if (props.version) {
        priority.unshift(props.version as MediaFormatVersion);
      }
      return [
        MediaUtils.PickMediaUrl(props.media, { priority }) ?? props.media.url,
        props.media.firstThumbnailUrl,
      ];
    }
    return [props.media.videoUrl, props.media.posterUrl];
  }, [props.media, props.version]);

  const videoRef = useRef(null);
  const playerRef = useRef<videojs.Player | null>(null);

  const vjsm = useChunkedDependency(() => import('video.js'));

  // const { options, onReady } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement || !vjsm) return;

      playerRef.current = vjsm.default(videoElement, {
        autoplay: autoplay,
        controls: true,
        fluid: false,
        fill: true,
        sources: [
          {
            src: url,
          },
        ],
        poster: posterUrl ?? undefined,
        bigPlayButton: !autoplay,
      });
    }
  }, [posterUrl, url, vjsm, autoplay]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  useEffect(() => {
    if (!download) return;

    triggerModal({
      kind: 'custom',
      element: (p) => (
        <DownloadModal
          url={url}
          filename={download}
          onCancel={p.internalOnCancel}
          onConfirm={p.internalOnConfirm}
        />
      ),
    });
  }, [download, triggerModal, url]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className='video-js vjs-big-play-centered' />
    </div>
  );
}

export function ImageViewer(props: {
  media: Media;
  version?: string | null;
}): JSX.Element {
  const url = useMemo(() => {
    const priority = [MediaFormatVersion.HD, MediaFormatVersion.Raw];
    if (props.version) {
      priority.unshift(props.version as MediaFormatVersion);
    }
    return (
      MediaUtils.PickMediaUrl(props.media, {
        priority,
      }) ?? props.media.url
    );
  }, [props.media, props.version]);
  return <img src={url} alt='' />;
}

export function AudioViewer(props: {
  media: Media;
  version?: string | null;
}): JSX.Element {
  const url = useMemo(() => {
    const priority = [MediaFormatVersion.HD, MediaFormatVersion.Raw];
    if (props.version) {
      priority.unshift(props.version as MediaFormatVersion);
    }
    return (
      MediaUtils.PickMediaUrl(props.media, { priority }) ?? props.media.url
    );
  }, [props.media, props.version]);

  return (
    <audio controls>
      <source src={url} />
      Your browser does not support the audio element.
    </audio>
  );
}

export function MediaViewer(props: {
  media: string | Media;
  version?: string | null;
  autoplay?: boolean;
  loadingStyle: 'global' | 'inline';
}): JSX.Element {
  const mediaId =
    typeof props.media === 'string' ? props.media : props.media.id;

  const {
    data: media,
    error,
    isLoading,
  } = useSWRImmutable(`/media/${mediaId}`, async () => {
    if (typeof props.media === 'string') {
      const predefinedMedia = PREDEFINED_MEDIA_LIST.find(
        (m) => m.id === props.media
      );
      if (predefinedMedia) return predefinedMedia;

      return (await apiService.media.getMedia(props.media)).data.media;
    }
    return props.media;
  });

  if (isLoading) {
    if (props.loadingStyle === 'global')
      return <GlobalLoading debug='media-viewer' />;
    return (
      <div className='w-full h-full flex items-center justify-center'>
        <Loading />
      </div>
    );
  }
  if (error) return <div>{err2s(error)}</div>;
  if (!media) return <div>Media Not Found</div>;

  switch (media.type) {
    case MediaType.Image:
      return <ImageViewer media={media} version={props.version} />;
    case MediaType.Video:
      return (
        <VideoViewer
          media={media}
          version={props.version}
          autoplay={props.autoplay}
        />
      );
    case MediaType.Audio:
      return <AudioViewer media={media} version={props.version} />;
    default:
      assertExhaustive(media.type);
      return <div>Unsupported Media</div>;
  }
}
